import React from 'react';
import { Link } from "react-router-dom";

const Offer = () => {

  return (
    <div id="oferta">
      <div className="title">
        <h2>Poznaj</h2>
        <p>Moją ofertę</p>
      </div>
      <div className="offer-boxes">
        <Link to="/oferta/twarz"><div className="offer-box face">Twarz</div></Link>
        <Link to="/oferta/dlonie"><div className="offer-box hands" style={{backgroundColor:"#84754E"}}>Dłonie</div></Link>
        <Link to="/oferta/stopy"><div className="offer-box feet">Stopy</div></Link>
      </div>
    </div>
  );
}

export default Offer;
