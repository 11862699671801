import React from 'react';
import '../../App.css';
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import Footer from "../../sections/Footer";
import MobileMenu from '../../sections/MobileMenu.js';
import Offer from '../../sections/Offer.js';

const OfferPage = ({data, title}) => {
  const { width } = useWindowDimensions();

  return (
    <div>
      <MobileMenu/>
      <div className="offer-title"><h1>{title}</h1></div>
      <div className="container">
        {
          data.map((item, index) => 
            <div className="single-offer-box">
            <div className="single-offer-title">
              <h2>{item.name}</h2>
              <div className="single-offer-price-time">
                <p>{item.time}</p>
                <p>{item.price}</p>
              </div>
            </div>
            <div className="single-offer-description">
              <p>{item.description}</p>
            </div>
          <div>
            <div className= { width < 992 ?"sinle-offer-details-mobile" :"sinle-offer-details"}>

            { data[index].indications.length > 0 ?
              <div className="indications">
              <h2>wskazania do zabiegu </h2>
                <ul>
                <li>{data[index].indications.map((item)=>item)}</li>
                </ul>
              </div>
              : ""
            }
            { data[index].contraindications.length > 0 ?
              <div className="contraindications">
              <h2>przeciwskazania do zabiegu </h2>
                <ul>
                  <li>{data[index].contraindications.map((item)=>item)}</li>
                </ul>
              </div>
              : ""
            }
            </div>

            {/*<div className="button-term"><button>Umów termin</button></div>*/}
          </div>
        </div>
        )
        }
      <Offer />
      <Footer />
      </div>
    </div>
  );
}

export default OfferPage;
