import React from 'react';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const Header = () => {
  const { width } = useWindowDimensions();

  return (
  <div className="header-box" loading="lazy">
    <div className="container">
      <h1 className="header">Odkryj Naturalne Piękno</h1>
      <p className="lead">Daj swojej urodzie chwilę relaksu w salonie urody Zacisze Piękna. Pozwól nam zadbać o Ciebie i podkreślić Twoje piękno w spokojnej atmosferze naszego salonu!</p>
      {width > 768
        ?<a href="#kontakt"><button className="cta">Umów się</button></a>
        :<a href="tel:784-318-411"><button className="cta">Umów się</button></a>
      }
    </div>
  </div>  
  );
}

export default Header;
