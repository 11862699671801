
export const DATA = {
  "face": [
    {
    "name" : "Oczyszczanie manualne twarzy",
    "description" : "to najbardziej popularny zabieg kosmetyczny. Polega na mechanicznym usunięciu zanieczyszczeń skórnych takich jak zaskórniki, prosaki, kaszaki. Zabieg zaczyna się od dokladnego demakijażu twarzy. Po oczyszczeniu twarzy cera jest tonizowana aby wyrównać jej poziom pH. Kolejnym krokiem jest wykonanie peelingu odpowiednio dobranego do rodzaju skóry. Gdy peeling został już zrobiony czas na rozpulchnienie skóry. Do rozpulchnienia używamy vapozonu który emituje parę wodną. Następnie przechodzimy do oczyszczania manualnego. Gdy wszystkie zmiany zostaną usunięte należy dokladnie zdezynfekować skórę a następnie nałożyć na nią maskę łagodzącą.",
    "indications": [
      "skora zanieczyszczona (zaskórniki, prosaki itp.)", 
      "skóra trądzikowa", 
      "zmiany grudkowo - krostkowe"
    ],
    "contraindications": [
      "zakażenie wirusowe ( opryszczka, brodawki)",
      "zakażenie bakteryjne",
      "uszkodzenie ciągłości skóry",
      "skłonności do pojawiania się bliznowców",
      "trądzik z aktywnym stanem zapalnym."
    ],
    "time": "60 min",
    "price": "120 PLN"
  },
  {
    "name" : "Oczyszczanie manualne pleców",
    "description" : "to najbardziej popularny zabieg kosmetyczny. Polega na mechanicznym usunięciu zanieczyszczeń skórnych takich jak zaskórniki, prosaki, kaszaki. Zabieg zaczyna się od dokladnego demakijażu twarzy. Po oczyszczeniu twarzy cera jest tonizowana aby wyrównać jej poziom pH. Kolejnym krokiem jest wykonanie peelingu odpowiednio dobranego do rodzaju skóry. Gdy peeling został już zrobiony czas na rozpulchnienie skóry. Do rozpulchnienia używamy vapozonu który emituje parę wodną. Następnie przechodzimy do oczyszczania manualnego. Gdy wszystkie zmiany zostaną usunięte należy dokladnie zdezynfekować skórę a następnie nałożyć na nią maskę łagodzącą.",
    "indications": [
      "skora zanieczyszczona (zaskórniki, prosaki itp.)", 
      "skóra trądzikowa", 
      "zmiany grudkowo - krostkowe"
    ],
    "contraindications": [
      "zakażenie wirusowe ( opryszczka, brodawki)",
      "zakażenie bakteryjne",
      "uszkodzenie ciągłości skóry",
      "skłonności do pojawiania się bliznowców",
      "trądzik z aktywnym stanem zapalnym."
    ],
    "time": "60 min",
    "price": "120 PLN"
  },
  {
    "name": "Peeling kawitacyjny twarzy",
    "description": "to bardzo lubiany i znany zabieg z zakresu kosmetologii pielęgnacyjnej, który oczyszcza skórę, za pomocą ultradźwieków. Bezpiecznie usuwa ze skóry zanieczyszczenia,martwe komórki,nadmiar zaskórników, sebum oraz bakterii. Jest to bezinwazyjny zabieg dający poczucie głebokiej pielęgnacji oraz regeneracji skóry.",
    "indications": ["rozszerzone pory", "skóra przetłuszczjąca się", "tradzik pospolity","zaskórniki"],
    "contraindications": ["ciąża",
      "karmienie piersią",
      "choroby nowotworowe",
      "metalowe implanty w ciele ( np. rozrusznik serca)",
      "przerwanie ciągłości skóry",
      "choroby baktryjne, wirusowe, skory"],
    "time": "60 min",
    "price": "140 PLN"
  },
  {
    "name": "Peeling kawitacyjny (twarz, szyja, dekolt)",
    "description": "to bardzo lubiany i znany zabieg z zakresu kosmetologii pielęgnacyjnej, który oczyszcza skórę, za pomocą ultradźwieków. Bezpiecznie usuwa ze skóry zanieczyszczenia,martwe komórki,nadmiar zaskórników, sebum oraz bakterii. Jest to bezinwazyjny zabieg dający poczucie głebokiej pielęgnacji oraz regeneracji skóry.",
    "indications": ["rozszerzone pory", "skóra przetłuszczjąca się", "tradzik pospolity","zaskórniki"],
    "contraindications": ["ciąża",
      "karmienie piersią",
      "choroby nowotworowe",
      "metalowe implanty w ciele ( np. rozrusznik serca)",
      "przerwanie ciągłości skóry",
      "choroby baktryjne, wirusowe, skory"],
    "time": "60 min",
    "price": "160 PLN"
  },
  {
    "name": "Mikrodermabrazja diamentowa twarzy",
    "description": "to zabieg polegający na mechanicznym złuszczeniu naskórka za pomocą kryształkow diamentu. Zabieg oczyszcza, wygładza, rozświetla i poprawia koloryt skóry.",
    "indications": ["poszarzała, zmęczona, pozbawiona witalności skóra",
      "postępujący proces starzenia się skóry",
      "przebarwienia, blizny i rozstępy",
      "trądzik, rozszerzone pory i zaskórniki",
      "uszkodzenia posłoneczne",
      "szorstki, zrogowaciały naskórek."],
    "contraindications": [
      "stany zapalne skóry",
  "trądzik różowaty, aktywny, ropny trądzik",
  "dermatozy zapalne np. łuszczyca",
  "skłonność do bliznowców",
  "płytko unaczyniona skóra",
  "infekcje skórne (np. brodawki wirusowe, aktywna opryszczka i inne)",
  "nowotwory skóry, włókniaki, znamiona",
  "przyjmowanie leków rozrzedzających krew (odstawić 24h przed zabiegiem)"
    ],
    "time": "60 min",
    "price": "140 PLN"
  },
  {
    "name": "Mikrodermabrazja diamentowa (twarz, szyja, dekolt)",
    "description": "to zabieg polegający na mechanicznym złuszczeniu naskórka za pomocą kryształkow diamentu. Zabieg oczyszcza, wygładza, rozświetla i poprawia koloryt skóry.",
    "indications": ["poszarzała, zmęczona, pozbawiona witalności skóra",
      "postępujący proces starzenia się skóry",
      "przebarwienia, blizny i rozstępy",
      "trądzik, rozszerzone pory i zaskórniki",
      "uszkodzenia posłoneczne",
      "szorstki, zrogowaciały naskórek."],
    "contraindications": [
      "stany zapalne skóry",
  "trądzik różowaty, aktywny, ropny trądzik",
  "dermatozy zapalne np. łuszczyca",
  "skłonność do bliznowców",
  "płytko unaczyniona skóra",
  "infekcje skórne (np. brodawki wirusowe, aktywna opryszczka i inne)",
  "nowotwory skóry, włókniaki, znamiona",
  "przyjmowanie leków rozrzedzających krew (odstawić 24h przed zabiegiem)"
    ],
    "time": "90 min",
    "price": "150 PLN"
  },
  {
    "name": "Oczyszczanie wodorowe twarzy",
    "description": "polega na dostarczeniu skórze czasteczek wodoru na poziomie komórkowym.Podczas zabiegu wykonywanych jest do pięciu różnych etapów. Na początku zabiegu wykonywany jest peeling kawitacyjny, który za pomocą ultradźwięków oczyszcza skórę z zanieczyszczeń i martwych komórek naskórka. Następnym krokiem jest oczyszczanie za pomocą wodoru i mieszaniny delikatnych kwasów. Skóra zostaje oczyszczona i odświeżona. Kolejne kroki oczyszczania to ultradźwięki lub fala radiowa. Jest to bardzo relaksujący moment zabiegu, który stymuluje skórę do wytarzania kolagenu i elastyny. Po takim przygotowaniu skóry możemy przejść do wtłaczania składników aktywnych za pomocą infuzji tlenowej. Kosmetolog dobiera odpowiednią ampułkę aktywną do typu skóry i podaje ją przy użyciu tlenu pod ciśnieniem.",
    "indications": ["oznaki przedwczesnego starzenia się",
      "cera szara, zmęczona o nierównomiernym kolorycie",
      "nadmierne wydzielanie sebum",
      "trądzik, zaskórniki, rozszerzone pory",
     "skóra wrażliwa, nadreaktywna ze skłonnoscia do stanów zapalnych"],
    "contraindications": ["aktywne choroby skóry",
      "patologiczne stany chorób przewlekłych ( np. Cukrzycy,nowotworóe, epilepsji)",
      "metalowe implanty",
      "rozrusznik serca",
      "nadwrażliwość na prąd elektryczny"],
    "time": "60 min",
    "price": "200 PLN"
  },{
    "name": "\"Black doll\" laserowy peelin węglowy",
    "description": "polega na naświetleniu laserem miejsca na które wcześniej nakładamy specjalną koloidalną zawiesinę z węgla.Dzięki działaniu długiej fali lasera drobinki węgla aktywnego absorbują energię swiatła laserowego i z łatwością wnikają w pory skóry.Zabieg stosuję się w celu redukcji zaskórnikow, przebarwien, stanów zapalnych np. jako dodatkowe działanie przy leczniu trądziku. Pomaga uzyskać takie rezultaty jak zmniejszenie rozszerzonych porów, zmniejszenie wydzelania sebum,redukcja zaczrwienienia,poprawa elastyczności skóry, wygładzenie jej czy zniwelowanie drobnych zmarszczek.",
    "indications": [
      "ziemista, szara cera",
  "skóra tłusta",
  "rozszerzone pory",
  "duża obecność zaskórników otwartych i zamkniętych",
  "nadmierne wydzielanie sebum",
  "drobne zmarszczki",
  "trądzik, krostki, grudki",
  "utrata jędrności skóry",
  "przebarwienia (w tym pozapalne przy leczeniu trądziku)",
    ],
    "contraindications": [
    "nadwrażliwość na światło",
  "zażywanie ziół światłouczulających (dziurawiec, nagietek, skrzyp, pokrzywa, duże ilości rumianku), należy odstawić tydzień przed zabiegiem",
  "zażywanie retinoidów, należy odstawić 6 miesięcy przed zabiegiem",
  "zażywanie antybiotyków i leków antydepresyjnych, należy odstawić tydzień przed zabiegiem",
  "przerwanie ciągłości skóry w miejscu poddanym zabiegowi",
  "aktywne infekcje wirusowe (opryszczka), bakteryjne, grzybicze w obrębie obszaru zabiegowego",
  "ciąża i laktacja",
  "bielactwo",
  "łuszczyca",
  "aktywna choroba nowotworowa, radioterapia, chemioterapia",
  "epilepsja"
    ],
    "time": "60 min",
    "price": "200 PLN"
  },{
    "name": "Mezoterapia mikroigłowa ( Nanopor sesderma)",
    "description": "to zabieg polegający na nakłuwaniu wybranej okolicy twarzy lub ciała, który odbywa sie w sposób bardzo intensywny- w czasie jego trwania powstają dziesiętki tysięcy małych nakłuć. Zabieg poprawia jędrnosc i elastyczność skóry. Pozwala zredukować zmarszczki mimiczne. Daję możliwośc zredukowania przebarwien czy blizn potrądzikowych. Skóra staje się prommienna i bardziej rozswietlona.",
    "indications": ["wiotka skóra z utratą elastyczności",
      "widoczne zmarszczki",
      "blizny",
      "rozstępy",
      "cellulit"],
    "contraindications": ["alergia",
      "ciąża",
      "karmienie piersią",
      "cukrzyca",
      "stany zapalne na skórze ( opryszczka)",
      "skóra źle tolerująca iniekcje",
      "choroby nowotworowe",
      "stosowanie leków przeciwkrzepliwych"],
    "time": "60 min",
    "price": "200 PLN"
  },{
    "name": "Masaż twarzy , szyji i dekoltu",
    "description": "jeden z najbardziej popularnych zabiegów relaksacyjnych. Stosuje się go w celu aktywizacji funkcji skóry twarzy szyi i dekoltu.Działa pomudzająca na receptory skóry i mięśni. Usprawnia krążenie krwi i limfy przez co skóra staje się bardziej dotleniona i odżywiona. Rozluźnia mięśnie twarzy przez co zapobiega utrwalaniu sie zmarszczek.",
    "indications": [],
    "contraindications": [],
    "time": "45 min",
    "price": "80 PLN"
  },{
    "name": "Peelingi chemiczne (kwasy) – SESDERMA",
    "description": "to innowacyjne, niezwykle skuteczne kwasy medyczne wykorzystujące odkrycia ŚWIATOWEJ DERMATOLOGII i MEDYCYNY ESTETYCZNEJ. Do produkcji swoich produktów wykorzystują technologię NANOSOMÓW – czyli liposomowych pęcherzyków, które docierają w bardzo głębokie warstwy naszej skóry. Peelingi chemiczne to zabieg polegający na kontrolowanym uszkodzeniu skóry substancją chemiczną, w celu pobudzenia w niej procesów regeneracji.",
    "indications": ["zanieczyszczona, szorstka skóra",
      "płytkie zmarszczki",
      "uszkodzenia posłoneczne- fotostarzenie",
      "trądzik pospolity, grudkowo-krostkowy, zaskórnikowy, różowaty",
      "skóra sucha",
      "skóra tłusta, łojotokowa z rozszerzonymi porami"],
    "contraindications": ["alergia na składniki peelingu",
      "ciąża",
      "karmienie piersią",
      "choroby nowotworowe",
      "zakażenia bakteryjne, wirusowe, grzybicze",
      "aktywne choroby zapalne skóry ( AZS, łuszczyca)",
      "planowana ekspozycja skóry na słońce",
      "fototerapia",
      "terapia przeciwtrądzikowa izotretynoiną ( minimum 3 miesiące po zakonczeniu kuracji)",
      "terapie sterydami"],
    "time": "60 mim",
    "price": "od 150 PLN"
  },
  ],
  "hands": [
    {
      "name" : "Manicure bez malowania",
      "description" : "Jest to podstawowy typ manicure. Polega na skróceniu i opiłowaniu paznokci w celu nadania im kształtu oraz usunięciu cążkami lub frezarką skórek.",
      "time": "30 min",
      "price": "50 PLN",
      "indications": [],
      "contraindications": [],
    },
    {
    "name" : "Manicure klasyczny",
    "description" : "to podstawowy typ manicure. Polega na skróceniu i opiłowaniu paznokci w celu nadania im kształtu oraz usunięciu cążkami lub frezarką skórek. Paznokieć polerujemy, odtłuszczamy, malujemy i nakładamy oliwkę nawilżającą.",
    "time": "30 min",
    "price": "60 PLN",
    "indications": [],
    "contraindications": [],
    },
    {
    "name" : "Manicure hybrydowy",
    "description" : "to zabieg pielęgnujący oraz stylizujący paznokcie u rąk. To trend w stylizacji paznokci, który od kilku lat cieszy się ogromnym powodzeniem. Polega na nałożeniu lakieru hybrydowego, który utwardzamy w lampie UV/LED. Połączenie lakieru i żelu gwarantuje 2-3 tyg. efekt pięknych paznokci.",
    "time": "60 min",
    "price": "90 PLN",
    "indications": [],
    "contraindications": [],
    },
    {
      "name" : "Manicure japonśki",
      "description" : "to zabieg dla osób walczących z kruchymi, łamliwymi oraz rozdwajającymi się paznokciami. Polega na wcieraniu w płytkę paznokcia pasty z witaminami i minerałami oraz naniesieniu na nią wartswy pudru. W efekcie paznokcie wyglądają na zdrowe i zadbane. Może byc doskonałą regeneracją po maniuce hybrydowym.",
      "time": "30 min",
      "price": "70 PLN",
      "indications": [],
      "contraindications": [],
      },
    {
      "name" : "Manicure SPA na dłonie",
      "description" : "W skład zabiegu wchodzi: manicure, serum, parafina. Jest to zabieg pielęgnacyjny, polecany szczególnie dla suchej i spierzchniętej skóry dłoni. Parafina tworzy naturalną powłokę ochronną, zmiękcza i regeneruje skórę. Podczas zabiegu oddaje ona ciepło, które głęboko wnika do tkanek, pobudza krążenie krwi oraz otwiera pory. Każdy kosmetyk który położymy pod parafinę bedzie się szybciej wchłaniał.",
      "time": "45 min",
      "price": "90 PLN",
      "indications": [],
      "contraindications": [],
      },
      {
        "name" : "Parafina na dłonie",
        "description" : "Parafina tworzy naturalną powłokę ochronną, zmiękcza i regeneruje skórę. Podczas zabiegu oddaje ona ciepło, które głęboko wnika do tkanek, pobudza krążenie krwi oraz otwiera pory.",
        "time": "30 min",
        "price": "30 PLN",
        "indications": [],
        "contraindications": [],
        }
  ], 
  "feet": [
    {
      "name": "Pedicure bez malowania",
      "description": "To zabieg mający na celu wypielęgnowanie oraz oczyszczenie skory i paznokci stóp. Kosmetolog za pomocą frezarki bardzo powierzchownie usuwa zrogowaciały naskórek z podeszwy stóp.",
      "time": "60 min",
      "price": "120 PLN",
      "indications": [],
      "contraindications": [],
      },
    {
    "name": "Pedicure klasyczny",
    "description": "To zabieg mający na celu wypielęgnowanie oraz oczyszczenie skory i paznokci stóp. Kosmetolog za pomocą frezarki bardzo powierzchownie usuwa zrogowaciały naskórek z podeszwy stóp. Następnie zajmuje się skróceniem oraz pielęgnacją paznokci i maluje je lakierem.",
    "time": "60 min",
    "price": "120 PLN",
    "indications": [],
    "contraindications": [],
    },
    {
      "name": "Pedicure hybrydowy",
      "description": "to najnowszy trend cieszący się bardzo duzym powodzeniem. W przeciwieństwie do tradycyjnych lakierów nakładamy tu lakiery utwardzane lampie UV/LED. I trwałość potrafi sięgac do miesiąca. Zabieg obejmuje opracowanie skóry stóp frezarką, opracowanie paznokci oraz skórek, nałożenie lakieru hybrydowego.",
      "time": "60 min",
      "price": "140 PLN",
      "indications": [],
      "contraindications": [],
    }, 
    {
      "name": "Pedicure kwasowy",
      "description": "to nieinwazyjny zabieg polecany dla osób, mających problem z popekaną skórą pięt oraz szybko narastającym naskórkiem na podeszwach stóp. Oprócz mechanicznego usuwania naskórka nakładamy również kwasy, które mają działanie dodatkowo złuszczające. Efekt gładkich stóp utrzymuje sięznacznie dłużej niż w przypadku pedicure tradycyjnego.",
      "time": "60 min",
      "price": "150 PLN",
      "indications": [],
      "contraindications": [],
    },
    {
      "name": "Pedicure leczniczy",
      "description": "to nic innego jak pedicure tradycyjny rozszerzony o usuniecie odcisków nagniotków, modzeli czy problemow z wrastającymi paznokciami. Wiąże się również z uszkodzoną płytka paznokcia na której mozemy wykonac rekonstrukcje specjalnie przeznaczonym do tego zelem.",
      "time": "60 min",
      "price": "150 PLN",
      "indications": [],
      "contraindications": [],
    }
  ]
}; 

