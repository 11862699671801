import React from 'react';
import '../../App.css';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import women from "../../images/women.jpeg";
import Footer from "../../sections/Footer";
import GallerySlider from "../../sections/GalerySlider/GallerySlider";
import MobileMenu from "../../sections/MobileMenu";
import Offer from '../../sections/Offer.js';

const AboutPage = () => {
  const { width } = useWindowDimensions();

  return (
    <>
    <MobileMenu/>
    <div>
    <div className="offer-title"><h1>O Mnie</h1></div>
    <div className="container">
    <div className="about-box" >
      <div className="about-description" >
        <p>W moim gabinecie kosmetycznym priorytetem jest dostarczanie usług najwyższej jakości, opartych na bogatym doświadczeniu i profesjonalizmie. Specjalizuję się nie tylko w pielęgnacji urody, ale także w skutecznym rozwiązywaniu problemów dermatologiczno-kosmetycznych. Wybierając moje usługi, możesz mieć pewność, że korzystasz z preparatów dermokosmetycznych i kosmetycznych najwyższej jakości, oraz skorzystasz z zabiegów o potwierdzonej skuteczności.</p>
        <p>Mój salon urody został stworzony z myślą o osobach, które pragną zadbać o swój piękny i zdrowy wygląd, a jednocześnie cenią sobie relaksującą atmosferę. Jako pasjonatka swojej pracy, oferuję wieloletnie doświadczenie zawodowe, zdobyte między innymi podczas pracy w renomowanym gabinecie kosmetycznym w Gdyni.</p>
        <p>Zapraszam do skorzystania z moich usług, aby odkryć potencjał swojej urody i cieszyć się kompleksową pielęgnacją skóry dostosowaną do indywidualnych potrzeb.</p>
      </div>
  
      { width < 992 
      ? ""
      : <div className="about-images">
          <div><img src={women} className="App-logo" alt="logo" /></div>
        </div>
      }
    </div>
    <div className="about-description">
        <p style={{marginBottom:"24px"}}>Posiadam liczne dyplomy i certyfikaty potwierdzające moje kwalifikacje, zdobyte podczas wielu lat praktyki. Ukończyłam studia I stopnia na kierunku kosmetologia w Wyższej Szkole Zdrowia Urody i Edukacji w Poznaniu – wydział zamiejscowy w Gdyni oraz studia II stopnia z zakresu zarządzania kosmetologią i spa.</p>
        <p style={{marginBottom:"24px"}}>Misją mojej pracy jest nie tylko pomoc Klientom w odnalezieniu ich naturalnej piękności i poprawie samopoczucia, lecz także promowanie zdrowia skóry poprzez profilaktyczne podejście. Dąże do doskonalenia swoich umiejętności poprzez ciągłe szkolenia, co pozwala mi sprostać najnowszym trendom i spełnić oczekiwania Klientów.</p>
        <p>Jestem przekonana, że rozwój, profesjonalne szkolenia i elastyczność w dostosowywaniu się do indywidualnych potrzeb Klientów są kluczowe. Moją największą satysfakcją jest osiąganie zadowolenia Klientów, a więc dołożę wszelkich starań, aby spełnić Państwa oczekiwania i zdobyć Państwa zaufanie.</p>
    </div>
    <p style={{textAlign:"right"}}>Serdecznie Zapraszam</p>
    <h3 className="signature"> Klaudia Jagielska-Klimowicz</h3>
    <GallerySlider/>    
    <Offer />
    <Footer />
    </div>
  </div>
  </>
  );
}

export default AboutPage;
