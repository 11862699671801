import React from 'react';
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import women from '../images/women.jpeg';

const About = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate()
  return (
    <>
    <div id="onas" className="title" style={{margin:"80px"}}>
      <h2>O Mnie</h2>
    </div>
    <div className="about-box" >
      <div className="about-description" >
        <p>W moim gabinecie kosmetycznym priorytetem jest dostarczanie usług najwyższej jakości, opartych na bogatym doświadczeniu i profesjonalizmie. Specjalizuję się nie tylko w pielęgnacji urody, ale także w skutecznym rozwiązywaniu problemów dermatologiczno-kosmetycznych. Wybierając moje usługi, możesz mieć pewność, że korzystasz z preparatów dermokosmetycznych i kosmetycznych najwyższej jakości, oraz skorzystasz z zabiegów o potwierdzonej skuteczności.</p>
        <p>Mój salon urody został stworzony z myślą o osobach, które pragną zadbać o swój piękny i zdrowy wygląd, a jednocześnie cenią sobie relaksującą atmosferę.</p>
        <button onClick={() => navigate('/omnie')} style={{"width": "100%"}}>Czytaj więcej</button>

      </div>
  
      { width < 992 
      ? ""
      : <div className="about-images">
          <div><img src={women} className="App-logo" alt="logo" /></div>
        </div>
      }
    </div>
    </>
  );
}

export default About;
