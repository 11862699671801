import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { DATA } from "./data/data";
import AboutPage from "./pages/About/AboutPage";
import Homepage from "./pages/Homepage/Homepage";
import OfferPage from "./pages/OfferPage/OfferPage";
import Project from "./pages/Project/Project";
import ScrollToTop from "./scrollToTop";


function App() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  return (
    <BrowserRouter>
    <ScrollToTop>
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/*<Route path="/oferta"  element={<Offers  face={DATA.face}  hands={DATA.face} feet={DATA.face}  />} />*/}
        <Route path="/oferta/twarz"  element={<OfferPage  title="Twarz" data={DATA.face}/>} />
        <Route path="/oferta/dlonie" element={<OfferPage title="Dłonie" data={DATA.hands} />} />
        <Route path="/oferta/stopy"  element={<OfferPage title="Stopy" data={DATA.feet} />} />
        <Route path="/projekt"  element={<Project />} />
        <Route path="/omnie"  element={<AboutPage />} />
      </Routes>
    </div>
    </ScrollToTop>
    </BrowserRouter>
  )
}

export default App;
