import React from 'react';
import '../../App.css';
import About from "../../sections/About";
import Footer from "../../sections/Footer";
import GallerySlider from "../../sections/GalerySlider/GallerySlider";
import Header from "../../sections/Header";
import MobileMenu from '../../sections/MobileMenu.js';
import Offer from '../../sections/Offer.js';

const Homepage = () => {
  return (
    <>
      <div className="container">
        <MobileMenu></MobileMenu>
        <Header />
        <About />
        <GallerySlider/>    
        <Offer />
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
