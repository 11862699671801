import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import image0 from "../../images/image0.jpeg";
import image1 from "../../images/image1.jpeg";
import image2 from "../../images/image2.jpeg";
import image2_1 from "../../images/image2_1.jpeg";
import image3 from "../../images/image3.jpeg";
import image5 from "../../images/image5.jpeg";
import image6 from "../../images/image6.jpeg";

const GallerySlider = () => {
  const { width } = useWindowDimensions();

  const images = [
    image0,
    image1,
    image2,
    image2_1,
    image6,
    image3,
    image5,
  ];

  function SampleNextArrow(props) {
    const { style } = props;
    return (
      <div
        style={{ ...style, display: "block", background: "none" }}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const {style } = props;
    return (
      <div
        style={{ ...style, display: "block", background: "none" }}
      />
    );
  }
  const sliderSettings = {
    infinite: true,
    speed: 5000,
    slidesToShow: width > 768 ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div className="gallery-slider" style={{ height: '600px', padding: "20px" }}>
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <div key={index}>
            <img 
              src={image} 
              loading="lazy"
              alt={`Slide ${index + 1}`}
              style={{ height: '500px', width: '100%', objectFit: 'cover' , padding: "8px"}}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default GallerySlider;
