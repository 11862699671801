import React from 'react';
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import fb from "../images/icons/facebook-square-brands.svg";
import instagram from "../images/icons/instagram-square-brands.svg";
import logo from '../images/logo.jpg';
import chata from "../images/logos/chata-kociewia.jpeg";
import leader from "../images/logos/leader.jpeg";
import prow from "../images/logos/prow.jpeg";
import ue from "../images/logos/ue.png";


const Footer = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate()
  return (
    <footer id="kontakt">
      <div className="title">
      {/*<h2>Skontaktuj się</h2>
      <p>ze Mną</p>*/}
      </div>
      <div className="social">
        Bądź z Nami w kontakcie na profilach społecznościowych: 
        <ul>
          <li><a href="https://www.facebook.com/ZaciszePieknaSalonUrody"><img src={fb} className="facebook" alt="Facebook" /></a></li>
          <li><img src={instagram} className="instagram" alt="Instagram" /></li>
        </ul>
      </div>
      <div className={ width < 992 ? "footer-box-mobile" : "footer-box" }>
        <div className="logoFooter">
        <a href={'/'}><img src={logo} className="App-logo" alt="logo" /></a>
        </div>
        <div className="address">
          <p><b>Adres</b></p>
          <p>ul. Urocza 12</p>    
          <p>83-200 Linowiec (Okole)</p>
        </div>

        <div className="contact">
          <p><b>Kontakt</b></p>
          <p>Telefon: <a href="tel:784-318-411">784-318-411</a> </p>
          <p>E-mail: <a href="mailto:zaciszepiekna@gmail.com">zaciszepiekna@gmail.com</a></p>
        </div>
      </div>
      <div className="project">
        <p>"Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich: Europa inwestująca w obszary wiejskie.”</p>

        <div className="project-logos" onClick={() => navigate('/projekt')}>
        <img src={ue} alt= "Unia Europejska"></img>
        <img src={leader} alt= "Leader"></img>
        <img src={chata} alt= "Chata Kociewia"></img>
        <img src={prow} alt= "Program Rozwoju Obszarów Wiejskich na lata 2014-2020"></img>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
