import React from 'react';
import Footer from "../../sections/Footer";
import MobileMenu from "../../sections/MobileMenu";
import ProjectSection from "../../sections/ProjectSection";
const Project = () => {
  return (
    <>
    <MobileMenu />
    <div className="container">
      <ProjectSection />
      <Footer />
    </div>
  </>
  );
}

export default Project;
