import React from 'react';
import chata from "../images/logos/chata-kociewia.jpeg";
import leader from "../images/logos/leader.jpeg";
import prow from "../images/logos/prow.jpeg";
import ue from "../images/logos/ue.png";
const ProjectSection = () => {
  return (
    <>
    <h1 id="project" style={{ paddingTop: 48, paddingBottom:48 }}>Dofinansowanie</h1>
    <h2>"Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich: Europa inwestująca w obszary wiejskie."</h2>
    <div className="project-logos">
          <img src={ue} alt= "Unia Europejska"></img>
          <img src={leader} alt= "Leader"></img>
          <img src={chata} alt= "Chata Kociewia"></img>
          <img src={prow} alt= "Program Rozwoju Obszarów Wiejskich na lata 2014-2020"></img>
      </div>
      <div className="project-box">
      <div>
        <p><storong>Tytuł operacji:</storong> „Utworzenie gabinetu kosmetycznego w miejscowości Linowiec”</p>
        <p>Cel i efekt: Stworzenie możliwości świadczenia usług kosmetycznych i zabiegów pielęgnacyjnych poprzez podjęcie działalności gospodarczej i zakup wyposażenia do gabinetu.</p>
        <p>Wartość projektu: 80 000.00 zł</p>
        <p>Operacja jest współfinansowana ze środków Unii Europejskiej w ramach poddziałania 19.2 „Wsparcie na wdrażanie operacji w ramach strategii rozwoju lokalnego kierowanego przez społeczność” Programu Rozwoju Obszarów Wiejskich na lata 2014–2020 </p>
      </div>
      </div>
  </>
  );
}

export default ProjectSection;
